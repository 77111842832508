<template>
  <div>
    <el-card>
      <el-form ref="form" :model="muopForm" :inline="true" size="small" @submit.native.prevent>
        <el-row>
          <el-col :md="8">
            <el-form-item label="模具编号:">
              <el-input v-model="muopForm.mdnc_no" placeholder="请输入模具编号" clearable />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searFrom" class="vg_ml_16" icon="el-icon-search">查询</el-button>
              <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()" icon="el-icon-refresh-right">刷新 </el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="4" class="vg-ml-15">
            <el-form-item>
              <el-button type="primary" size="small" @click="addCard">开卡</el-button>
              <el-button type="danger" size="small" @click="delCard()" :disabled="isDisabled">销卡</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-table
        class="vg_cursor"
        border
        :data="cardList"
        style="width: 100%"
        @sort-change="sortChange"
        @selection-change="handleSelectionChange"
        @cell-dblclick="dblclick"
      >
        <el-table-column type="index" label="序号" align="center" />
        <el-table-column type="selection" align="center" />
        <el-table-column prop="stff_name" label="开卡人" />
        <el-table-column prop="mdnc_no" label="模具编号" />
        <el-table-column prop="requ_no" label="委托单号" />
        <el-table-column prop="murf_no" label="卡号" />
        <el-table-column prop="murf_status" label="卡使用状态" align="center" sortable>
          <template v-slot="scope">
            <el-tag size="small" :type="getRfidType(scope.row.murf_status)">
              {{ getRfidStatus(scope.row.murf_status) }}
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
      <pubPagination :totalPage="total" @changePageSearch="changeSampPageSearch" ref="pubPagination" />
    </el-card>
  </div>
</template>
<script>
import { getNoCatch, post, postNoCatch } from '@api/request';
import { rfidAPI } from '@api/modules/rfid'; //rfid
import pubPagination from '@/components/common/pubPagination';
import selectSectionType from '@/views/component/selectSectionType';
import { muopAPI } from '@api/modules/muop';

export default {
  name: 'muopCard',
  components: {
    pubPagination,
    selectSectionType
  },
  data() {
    return {
      muopForm: {
        page_no: 1
      },
      total: 0,
      cardList: [],
      multipleSelection: [],
      defaultValType: '',
      selectRefuDisabled: false,
      isDyj: true,
      beloDeptId: null,
      options: [
        { value: 0, label: '未使用' },
        { value: 1, label: '已完成' },
        { value: 2, label: '正在使用' }
      ]
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === '/muop_list') {
        this.getCardList();
      }
    }
  },
  created() {
    this.getCardList();
  },
  methods: {
    getCardList() {
      getNoCatch(muopAPI.getMurfs, this.muopForm).then(({ data }) => {
        this.cardList = data.list;
        this.total = data.total;
      });
    },
    changeSampPageSearch(val) {
      this.muopForm.page_no = val;
      this.getCardList();
    },
    searFrom() {
      this.muopForm.page_no = 1;
      this.$refs.pubPagination.currentPage = 1;
      this.getCardList();
    },
    // 刷新
    buttonRefresh() {
      this.muopForm = {
        page_no: 1
      };
      this.currentPage = 1;
      this.$refs.pubPagination.currentPage = 1;
      this.getCardList();
    },
    addCard() {
      this.jump('muop_add');
    },
    delCard() {
      if (this.multipleSelection.length === 0) {
        return this.$message.warning('至少选择一条数据');
      }
      this.$confirm('此操作将永久删除该卡号, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let murf_id_list = this.multipleSelection.map(({ murf_id }) => Object.values({ murf_id }).toString());
          post(muopAPI.destroyMurfByIds, { murf_id_list }).then(res => {
            if (res.data.code === 1) {
              return this.$message.warning('状态是正在使用或已完成的卡是不可以销卡');
            }
            if (res.data.code !== 0) {
              return this.$message.error(res.data.msg);
            }
            this.$message.success(res.data.msg);
            this.getCardList();
          });
        })
        .catch(() => {
          this.$message.info('已取消删除');
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    dblclick(val) {
      console.log(val);
      this.jump('muop_edit', { murf_id: val.murf_id });
    },
    //查询范围
    getStatusVal2(val) {
      this.muopForm.belo_dept_id = val;
    },
    //升序 降序
    sortChange(val) {
      if (val.order == 'ascending') {
        this.muopForm.muop_sort = 'asc';
        this.muopForm.muop_field = val.prop;
      } else if (val.order == 'descending') {
        this.muopForm.muop_sort = 'desc';
        this.muopForm.muop_field = val.prop;
      } else {
        this.muopForm.muop_sort = '';
        this.muopForm.muop_field = '';
      }
      this.muopForm.page_no = 1;
      this.getCardList();

      // 升序 asc
      // 降序 desc
    },
    //获取卡的状态
    getRfidStatus(status) {
      if (status === 0) {
        return '正在使用';
      } else if (status === 1) {
        return '已完成';
      } else if (status === 2) {
        return '未使用';
      }
    },
    //通过状态改变 tag的颜色
    getRfidType(status) {
      if (status === 0) {
        return '';
      } else if (status === 1) {
        return 'success';
      } else if (status === 2) {
        return 'warning';
      }
    }
  },
  computed: {
    isDisabled() {
      if (this.multipleSelection.length == 0) {
        return true;
      }
      let f = this.multipleSelection.some(item => {
        return item.rfid_status === 1;
      });
      return f;
    }
  }
};
</script>

<style scoped>
.el-table {
  margin-top: 15px;
}

.vg-ml-15 {
  margin-top: 15px;
}

::v-deep .el-form-item__content {
  margin-left: 0;
}

.vg_ml_32 {
  margin-left: 32px;
}
</style>
